.Pagination {
  float: left;
  width: 100%;
  height: 56px !important;
  padding: 4px;
}

.Pagination .PaginationNext,
.Pagination .PaginationPrev,
.Pagination .PaginationPerPage {
  float: right;
  width: 48px;
  height: 48px;
  padding: 12px;
  cursor: pointer;
  fill: rgba(0, 0, 0, .56);
  color: rgba(0, 0, 0, .56);
}

.Pagination .PaginationPerPage {
  width: auto;
}

.Pagination .PaginationPerPage p,
.Pagination .PaginationPerPage svg {
  float: left;
}

.Pagination .PaginationPerPage p {
  font-size: 14px;
  padding: 4px 0;
  margin: 0;
}

.Pagination .PaginationNext:hover,
.Pagination .PaginationPrev:hover,
.Pagination .PaginationPerPage:hover {
  fill: rgba(0, 0, 0, .87);
  color: rgba(0, 0, 0, .87);
}

.Pagination .PaginationNext.disable,
.Pagination .PaginationPrev.disable {
  fill: rgba(0, 0, 0, .24);
  color: rgba(0, 0, 0, .24);
  cursor: default;
}

.Pagination .PaginationNext svg,
.Pagination .PaginationPrev svg,
.Pagination .PaginationPerPage svg {
  width: 24px;
  height: 24px;
  fill: inherit;
}

.Pagination .PaginationInfo,
.Pagination .PaginationRowsPerPage {
  float: right;
  width: auto;
  height: 48px;
  font-size: 14px;
  color: rgba(0, 0, 0, .56);
  cursor: default;
}

.Pagination .PaginationRowsPerPage {
  width: auto;
  padding-right: 0;
}

.Pagination .PaginationPerPage .PaginationPerPagePopup {
  display: none;
  position: absolute;
  width: 48px;
  height: auto;
  background: #ffffff;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .24);
  cursor: pointer;
  color: rgba(0, 0, 0, .56);
}

.Pagination .PaginationPerPage .PaginationPerPagePopup.show {
  display: block;
}

.Pagination .PaginationPerPage .PaginationPerPagePopup p {
  width: 100%;
  font-size: 14px;
  padding: 8px;
  margin: 0;
  text-align: center;
}

.Pagination .PaginationPerPage .PaginationPerPagePopup p:hover {
  background: #eeeeee;
  color: rgba(0, 0, 0, .87);
}

.Pagination .PaginationPerPage .PaginationPerPagePopup p.active {
  background: #e0e0e0;
  color: rgba(0, 0, 0, .87);
}
