.Accordion,
.Accordion .Accordion-Card,
.Accordion .Accordion-Card .Accordion-CardContent {
  float: left;
  width: 100%;
  height: auto;
}

.Accordion .Accordion-Card {
  margin: 0;
  transition: margin 0.2s ease;
}

.Accordion .Accordion-Card.Accordion-Mid {
  border-radius: 0;
}

.Accordion .Accordion-Card.Accordion-First {
  border-radius: 4px 4px 0 0;
}

.Accordion .Accordion-Card.Accordion-Last {
  border-radius: 0 0 4px 4px;
}

.Accordion .Accordion-Card:hover,
.Accordion .Accordion-Card.Accordion-Active {
  background: #F5F5F5;
  cursor: pointer;
}

.Accordion .Accordion-Card.Accordion-Active {
  background: #FAFAFA;
  cursor: pointer;
}

.Accordion .Accordion-Card.Accordion-Disabled {
  background: #FFFFFF;
  cursor: default;
}

.Accordion .Accordion-Card.Accordion-Active {
  cursor: default;
}

.Accordion .Accordion-Card .Accordion-CardContent {
  padding: 16px;
}

.Accordion .Accordion-Card .Accordion-Title {
  float: left;
  width: 100%;
  min-height: 24px;
  padding: 17px 16px;
  cursor: pointer;
}

.Accordion .Accordion-Card .Accordion-Title .Accordion-ChevronIcon {
  float: right;
  width: 24px;
  height: 24px;
  fill: rgba(0, 0, 0, .56);
}

.Accordion .Accordion-Card .Accordion-Title .Accordion-ChevronIcon svg {
  float: left;
  width: 24px;
  height: 24px;
  fill: inherit;
  display: none;
}

.Accordion .Accordion-Card .Accordion-Title .Accordion-ChevronIcon svg.Accordion-ChevronIcon-ExpandMore {
  display: block;
}

.Accordion .Accordion-Card .Accordion-Title .Accordion-ChevronIcon svg.Accordion-ChevronIcon-ExpandLess {
  display: none;
}

.Accordion .Accordion-Card.Accordion-Active .Accordion-Title .Accordion-ChevronIcon svg.Accordion-ChevronIcon-ExpandMore {
  display: none;
}

.Accordion .Accordion-Card.Accordion-Active .Accordion-Title .Accordion-ChevronIcon svg.Accordion-ChevronIcon-ExpandLess {
  display: block;
}

.Accordion .Accordion-Card:hover .Accordion-Title .Accordion-ChevronIcon {
  fill: rgba(0, 0, 0, .87);
}

.Accordion .Accordion-Card.Accordion-Active .Accordion-Title {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  height: 56px;
  font-weight: bold;
}

.Accordion .Accordion-Card .Accordion-Content {
  float: left;
  width: 100%;
  height: auto;
  display: none;
  color: rgba(0, 0, 0, .56);
}

.Accordion .Accordion-Card.Accordion-Active .Accordion-Content {
  float: left;
  display: block;
  padding-bottom: 0;
  background: #FAFAFA;
}

.Accordion .Accordion-Card.Accordion-Active {
  margin: 20px 0;
}
