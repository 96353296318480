.DrawerContent {
  padding-bottom: 24px;
}

#DrawerContent-Header {
  background: #424242;
}

.DrawerContent .Active-Nav {
  color: #FFFFFF;
  background: #757575;
}

.DrawerContent ul>div:hover {
  background: #BDBDBD;
}

.DrawerContent .Active-Nav:hover {
  background: #757575;
}

@media (min-width:0px) and (orientation: landscape) {
  .DrawerContent div img {
    margin: 4px 24px;
  }
}

.DrawerContent div img {
  width: 193px;
  height: 40px;
  float: left;
  margin: 8px 24px;
}

@media (min-width:600px) {
  .DrawerContent div img {
    margin: 12px 24px;
  }
}
