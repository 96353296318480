.SearchBar {
  width: 100%;
  height: 56px !important;
  margin-bottom: 24px;
}

.SearchBar,
.SearchBar .SearchBar-Card,
.SearchBar .SearchBar-Card .SearchBar-Content {
  height: 56px;
}

.SearchBar .SearchBar-Card .SearchBar-Content {
  padding: 4px 16px;
}

.SearchBar .SearchBar-Card .SearchBar-Content input {
  float: left;
  width: 95%;
  height: 48px;
  border: none;
  outline: none;
  font-size: 16px;
  color: rgba(0, 0, 0, .56);
}

.SearchBar .SearchBar-Card .SearchBar-Content .Search-Action {
  float: left;
  width: 5%;
  height: 48px;
}

.SearchBar .SearchBar-Card .SearchBar-Content .Search-Action .Search-Icon {
  float: right;
  width: 48px;
  height: 48px;
  padding: 12px;
  cursor: pointer;
  fill: rgba(0, 0, 0, .56);
}

.SearchBar .SearchBar-Card .SearchBar-Content .Search-Action .Search-Icon:hover {
  fill: rgba(0, 0, 0, .87);
}

.SearchBar .SearchBar-Card .SearchBar-Content .Search-Action svg {
  width: 24px;
  height: 24px;
  fill: inherit;
}
