.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

.img-cover {
    object-fit: contain !important;
    transition: .3s ease-in;
}

.bg-secondary {
    background: #EFEFEF !important;
}

.img-null {
    object-fit: contain !important;
    cursor: pointer;
    transition: .3s ease-in;
    filter: brightness(80%);
}

.image-wrapper {
    margin: auto;
    height: fit-content !important;
}

.Avatar {
    width: 200px;
    height: 200px;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px !important;
}

.close-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-size: cover;
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer !important;
}
  
.camera-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-size: cover;
    position: absolute;
    right: 0px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    cursor: pointer !important;
}

.position-relative {
    position: relative;
}
  
.fit-width {
    width: fit-content !important;
}

.rounded-circle {
    border-radius: 50%!important;
}